

import { useState,useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";


const Header=()=>{
  const location = useLocation();
  const isEduManagePage = location.pathname.includes("best-school-and-college-management-software");
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
      AOS.init({ 
        duration: 1000,
        easing: 'ease-in-out',
        delay: 200,
        once: false
      });
    }, []);
  
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  
    // Function to close menu when a navigation link is clicked
    const handleNavLinkClick = () => {
      setMenuOpen(false);
    };
  
    return(
        <>
       
        
        <nav>
       

       <div className="wrapper ">
       <div className="logo">
      <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/${isEduManagePage ? "images/edumanage.webp" : "Logo.webp"}`}
          alt="Logo"
          loading="lazy"
          style={{ width: isEduManagePage ? "75px" : "50%" }}
        />
      </NavLink>
    </div>

         <input type="checkbox" id="menu-btn" checked={menuOpen} onChange={toggleMenu} />
         <input type="checkbox" id="close-btn" checked={!menuOpen} onChange={toggleMenu} />
         <ul className={`nav-links ${menuOpen ? 'show' : ''}`}>
           <label htmlFor="close-btn" className="btn close-btn">
             <span><img src={`${process.env.PUBLIC_URL}/images/close.webp`} alt='close-btn' width="25" height="25" /></span>
           </label>
           <li><NavLink to="/" end onClick={handleNavLinkClick} >Home</NavLink></li>
           <li><NavLink to="/WebDesign-and-Digital-Marketing-Experts" onClick={handleNavLinkClick} >About</NavLink></li>
           <li>
             <NavLink to="/services" className="desktop-item" onClick={handleNavLinkClick} >Services</NavLink>
             <input type="checkbox" id="showDrop" />
             <label htmlFor="showDrop" className="mobile-item">Services</label>
             <ul className="drop-menu">
               <li><NavLink to="/webdesigning-company-in-palakkad-kerala/" onClick={handleNavLinkClick} >Web Development</NavLink></li>
               <li><NavLink to="/best-digital-marketing-strategist-in-palakkad" onClick={handleNavLinkClick} >Digital Marketing</NavLink></li>
               <li><NavLink to="/seo-company-in-palakkad-kerala " onClick={handleNavLinkClick} >SEO</NavLink></li>
               <li><NavLink to="/Professional-Photography-and-Videography-Services" onClick={handleNavLinkClick} >Photography & Videography</NavLink></li>
               <li><NavLink to="/Best-Content-Creation-Services-in-palakkad" onClick={handleNavLinkClick} >Content Creation</NavLink></li>
               <li><NavLink to="/ecommerce-website-development-company-in-kerala" onClick={handleNavLinkClick} >Ecommerce Development</NavLink></li> 
             </ul>
           </li>

           <li>
  <NavLink to="#" className="desktop-item">Products</NavLink>
  <input type="checkbox" id="showDrop1" className="toggle-checkbox" />
  <label htmlFor="showDrop1" className="mobile-item">Products</label>
  <ul className="drop-menu">
    <li><NavLink to="/sales-tracking-management-app" onClick={handleNavLinkClick}>Sale App</NavLink></li>
    <li><NavLink to="/best-school-and-college-management-software" onClick={handleNavLinkClick}>EduManage</NavLink></li>
  </ul>
</li>

           <li><NavLink to="/Trice-Technologies-Blog-Latest-Tech-Trends" onClick={handleNavLinkClick} >Blogs</NavLink></li>
           <li><NavLink to="/Contact-Trice-Technologies-Get-in-Touch-Today" onClick={handleNavLinkClick} >Contact</NavLink></li>
         </ul>
         <label htmlFor="menu-btn" className="btn menu-btn">
           <span><img src={`${process.env.PUBLIC_URL}/images/menu.webp`} alt='menu' width="25" height="25" /></span>
         </label>
         <div className="social-media-icons">
   
   <a href="https://www.linkedin.com/company/trice-technologies-kerala/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/linkdin.webp`} alt='Linkedin' width='100%' height='100%'/></span><span class="sr-only">LinkedIn</span></a>
   <a href="https://www.facebook.com/tricetechnologiesindia/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/fb.webp`} alt='Facebook' width='100%' height='100%' /></span><span class="sr-only">LinkedIn</span></a>
   <a href="https://www.instagram.com/trice_technologies/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/insta.webp`} alt='Instagram' width='100%' height='100%'/></span><span class="sr-only">LinkedIn</span></a>
 </div>
       </div>
     </nav>
        </>
    )
}

export default Header;