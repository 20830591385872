import { Modal, Button, Form } from 'react-bootstrap';
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import FloatingButton from '../Services/FloatingButton';
import { Helmet } from 'react-helmet';

import PricingPlans from '../PricingPlan';
const EduManage=()=>{
   
 const [show, setShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("Free Trial For 1 year");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    school: "",
    location: "",
    district: "",
    plan: selectedPlan
  });

  const handleShow = () => {
   
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false); // Reset loading state when modal is closed
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    try {
      const response = await fetch("https://tricetechnologies.in/register.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, plan: selectedPlan })
      });

      const data = await response.json(); // Parse JSON

alert(data.message);
      handleClose();
    } catch (error) {
      alert("Something went wrong! Please try again.");
      setLoading(false); // Stop spinner on error
    }
  };


    
    return(
        <>
         <Helmet>
        <title>Edumanage</title>
        <meta name='title' content=''/>
        <meta name="description" content="" />

        <meta property="og:type" content="website" />
    <meta property="og:title" content="Edumanage - Best School and College Management Software" />
    <meta property="og:description" content="Edumanage offers an all-in-one platform for schools and colleges to streamline management, boost efficiency, and improve education quality." />
 
    
    <meta property="og:image" content="https://tricetechnologies.in/images/og/Trice-Edu-Manage.jpg" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
        <link rel="canonical" href="https://tricetechnologies.in/best-school-and-college-management-software" />
        
</Helmet>



        <div className="container pt-5">
            <div className="row pt-5 align-items-center">
            <div className="col-md-6 align-items-center sale-banner-left">
                        <div className="">
                        
                            
                            <h1 class="font-weight-bold wow" style={{fontSize: '37px'}}>Smart <span class="text-primary">Educational</span> Management<br></br><p>Solution</p></h1>
                        <p>Trice Edumanage Educational Software is designed to simplify and streamline your educational institution's processes efficiently. We make it easy to manage admissions, exams, fees, timetables, and library management</p>
                        </div>
                     
                        <Button className="demo-btn my-3" onClick={handleShow}>
                        Get 1 Year free
                    </Button>


                    <Modal show={show} onHide={handleClose} centered className='p-4'>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Name</label>
              <input type="text" name="name" className="form-control inputs" placeholder="Enter your name" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">Mobile</label>
              <input type="tel" name="mobile" className="form-control inputs" placeholder="Enter your mobile number" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">Email</label>
              <input type="email" name="email" className="form-control inputs" placeholder="Enter your email" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">School Name</label>
              <input type="text" name="school" className="form-control inputs" placeholder="Enter school name" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">Location</label>
              <input type="text" name="location" className="form-control inputs" placeholder="Enter location" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">District</label>
              <input type="text" name="district" className="form-control inputs" placeholder="Enter district" required onChange={handleChange} />
            </div>
           
            
            <button type="submit" className="btn btn-primary p-2 mt-3" disabled={loading}>
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> Registering...
                </>
              ) : (
                "Register"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
                    
                      
                    </div>
                    <div className="col-md-6">
                    <img src={`${process.env.PUBLIC_URL}/images/products/management.webp`}/>
                    </div>
            </div>

            

        </div>

        <div className="container my-5">
    <div className="col-md-12 my-5 d-none d-md-block">
        <ul className="management-list">
            <li><a href="#admission"><i className="fa fa-check-circle"></i> Admission Management</a></li>
            <li><a href="#curriculam"><i className="fa fa-check-circle"></i> Co Curriculum Management</a></li>
            <li><a href="#students"><i className="fa fa-check-circle"></i> Students Management</a></li>
            <li><a href="#teachers"><i className="fa fa-check-circle"></i> Teachers Management</a></li>
            <li><a href="#exam"><i className="fa fa-check-circle"></i> Exam Management</a></li>
            <li><a href="#timetable"><i className="fa fa-check-circle"></i> Time Table Management</a></li>
            <li><a href="#staff"><i className="fa fa-check-circle"></i> Support Staff Management</a></li>
            <li><a href="#payroll"><i className="fa fa-check-circle"></i> Payroll Management</a></li>
            <li><a href="#library"><i className="fa fa-check-circle"></i> Library Management</a></li>
            <li><a href="#store"><i className="fa fa-check-circle"></i> Store Management</a></li>
            <li><a href="#hostel"><i className="fa fa-check-circle"></i> Hostel Management</a></li>
            <li><a href="#infrastructure"><i className="fa fa-check-circle"></i> Infrastructure Management</a></li>
            <li><a href="#fee"><i className="fa fa-check-circle"></i> Fee Management</a></li>
            <li><a href="#accounts"><i className="fa fa-check-circle"></i> Cost Management</a></li>
            <li><a href="#vehicle"><i className="fa fa-check-circle"></i> Vehicle Management</a></li>
        </ul>
    </div>
</div>

<br></br>


        
        <PricingPlans/>

        <br></br>
        <div className='container-fluid edumanage-feature'>
            <div className='container'>
              
            <div className="row p-3">
  <div className="col-md-5">
    <h2 className="" style={{ fontSize: '57px', color: '#000' }}>
      Smarter Solutions,<br /> Faster Results!
    </h2>
    <p>
      Our software brings a revolutionary approach to institute management,
      streamlining every aspect of operations with advanced automation and
      smart integrations. By simplifying complex processes, it empowers
      institutions to achieve greater efficiency while significantly <span><b>Reducing
      COST, EFFORT, and TIME.</b></span>
    </p>
  </div>
  <div className="col-md-7">
    <div className='inside-box'>
        <p><span className='span1'>Trice</span><span className='span2'> Edumanage</span> by Trice Technologies is a versatile  Educational management software that revolutionizes how educational institutions manage their operations. As a comprehensive <a href='/college-management-system'  style={{color:'#f6821f'}}>college management software system,</a> it integrates modules such as Accounts, Teachers, Library, and Exam Management, accessible through both web and mobile platforms.

This all-in-one solution streamlines student enrollment, automates fee collection, and facilitates effective monitoring of academic and non-academic activities. With intuitive tools, it minimizes errors, enhances resource utilization, and boosts accountability.

Trice Edumanage also serves as an advanced <a href='/school-management-software-palakkad-kerala' style={{color:'#f6821f'}}>school management software system,</a> offering a unified platform for schools, colleges, and institutes to optimize productivity and achieve operational excellence.</p>
    </div>
   
  </div>
  
</div>
            </div>
        </div>
        
        <div className="container my-5">
    <div className="col-md-12 my-5 d-block d-sm-none">
        <ul className="management-list">
            <li><a href="#admission"><i className="fa fa-check-circle"></i> Admission Management</a></li>
            <li><a href="#curriculam"><i className="fa fa-check-circle"></i> Co Curriculum Management</a></li>
            <li><a href="#students"><i className="fa fa-check-circle"></i> Students Management</a></li>
            <li><a href="#teachers"><i className="fa fa-check-circle"></i> Teachers Management</a></li>
            <li><a href="#exam"><i className="fa fa-check-circle"></i> Exam Management</a></li>
            <li><a href="#timetable"><i className="fa fa-check-circle"></i> Time Table Management</a></li>
            <li><a href="#staff"><i className="fa fa-check-circle"></i> Support Staff Management</a></li>
            <li><a href="#payroll"><i className="fa fa-check-circle"></i> Payroll Management</a></li>
            <li><a href="#library"><i className="fa fa-check-circle"></i> Library Management</a></li>
            <li><a href="#store"><i className="fa fa-check-circle"></i> Store Management</a></li>
            <li><a href="#hostel"><i className="fa fa-check-circle"></i> Hostel Management</a></li>
            <li><a href="#infrastructure"><i className="fa fa-check-circle"></i> Infrastructure Management</a></li>
            <li><a href="#fee"><i className="fa fa-check-circle"></i> Fee Management</a></li>
            <li><a href="#accounts"><i className="fa fa-check-circle"></i> Cost Management</a></li>
            <li><a href="#vehicle"><i className="fa fa-check-circle"></i> Vehicle Management</a></li>
        </ul>
    </div>
</div>

       
        <div className='container-fluid'>

        <div className='row mt-5 ' id='admission'>
        <div className='col-md-12 text-center my-5 feature-head'>
                <h2 className="" style={{ fontSize: '37px',color: '#000'}}>Features Overview</h2>
                <br></br>
                </div>
               
               <div className='col-md-6 text-right'>
               <img src={`${process.env.PUBLIC_URL}/images/products/admissions.webp`}/>
               </div>
               <div className='col-md-6 p-4 right-content'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Admission Management</h2>
                   <p>Streamline the admissions process</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> From Application to Enrollment</li>
                   <li><i class="fa fa-check"></i> Manage Applicant Data</li>
                   <li><i class="fa fa-check"></i> Track Admissions Status</li>
                   <li><i class="fa fa-check"></i> Dedicated Online Payment Web page</li>
                  
               </ul>
               <p>and generate admission-related reports effortlessly</p>
               </div>
       </div>


       <div className='row mt-5' id='curriculam'>
       <div className='col-md-6 p-4 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Co Curriculum Management</h2>
                        <p>Introducing Co Curriculum Management, a feature designed to enrich the educational experience beyond the classroom</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Comprehensive Activity Tracking</li>
                        <li><i class="fa fa-check"></i> Expense Management</li>
                        <li><i class="fa fa-check"></i> Student Enrollment for Activities</li>
                        <li><i class="fa fa-check"></i> Activity Scheduling</li>
                        <li><i class="fa fa-check"></i> Performance Reporting</li>
                       
                    </ul>
                    <p>Easily manage all Co Curricular activities, like Sports, Arts, Clubs, and more, all in one centralized platform.</p>
                    </div>
            <div className='col-md-6 text-left'>
              
            <img src={`${process.env.PUBLIC_URL}/images/products/CoCurriculum.webp`}/>
                    </div>
           
                  
                   
            </div>


            <div className='row mt-5' id='students'>
               
               <div className='col-md-6 p-4'>
               <img src={`${process.env.PUBLIC_URL}/images/products/students.webp`} className='mx-auto d-block w-70'/>
               </div>
               <div className='col-md-6 p-4 right-content'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Students Management</h2>
                   <p>Providing a comprehensive view of student progress and performance</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> Maintain Student Records</li>
                   <li><i class="fa fa-check"></i> Personal Details</li>
                   <li><i class="fa fa-check"></i> Academic History</li>
                   <li><i class="fa fa-check"></i> Attendance</li>
                   <li><i class="fa fa-check"></i> Disciplinary Records</li>
                  
               </ul>
              
               </div>
       </div>


       <div className='row mt-5' id='teachers'>
            <div className='col-md-6 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Teachers Management</h2>
                        <p>Efficiently manage faculty information</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Qualifications</li>
                        <li><i class="fa fa-check"></i> Class Schedules</li>
                        <li><i class="fa fa-check"></i> Attendance</li>
                        <li><i class="fa fa-check"></i> Performance Evaluations</li>
                       
                    </ul>
                    <p>ensuring optimal staffing and resource allocation.</p>
                    </div>
                    <div className='col-md-6 text-left'>
                    <img src={`${process.env.PUBLIC_URL}/images/products/teacher.webp`} className='w-70'/>
                    </div>
                   
            </div>

            <div className='row mt-5' id='exam'>
               
               <div className='col-md-6 mt-3 text-right'>
               <img src={`${process.env.PUBLIC_URL}/images/products/accounts.webp`} className='mx-auto d-block'/>
               </div>
               <div className='col-md-6 mt-3 right-content p-4'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Exam Management</h2>
                   <p>Seamlessly track and manage finances, including budgeting, invoicing, payments, and financial reporting, ensuring transparency and accuracy in financial management.</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> Manages exam scheduling</li>
                   <li><i class="fa fa-check"></i> Student exam registration</li>
                   <li><i class="fa fa-check"></i> Result processing</li>
                   <li><i class="fa fa-check"></i> Grade distribution</li>
                  
               </ul>
               </div>
       </div>

       <div className='row mt-5' id='timetable'>
            <div className='col-md-6 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>TimeTable Management</h2>
                        <p>Efficient timetable management ensures streamlined scheduling, reduces conflicts, and maximizes productivity. It enables seamless organization of activities, saving time and enhancing overall operational efficiency.</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Automated timetable generation</li>
                        <li><i class="fa fa-check"></i> Faculty and room availability tracking</li>
                        <li><i class="fa fa-check"></i> Conflict resolution (avoiding clashes in schedules)</li>
                        <li><i class="fa fa-check"></i> Time slot allocation for lectures, labs, and extracurricular activities</li>
                        <li><i class="fa fa-check"></i> Notifications and updates for students and faculty</li>
                        <li><i class="fa fa-check"></i> Exportable and shareable schedules (PDF/Excel)</li>
                    </ul>
                   
                    </div>
                    <div className='col-md-6 text-left'>
                    <img src={`${process.env.PUBLIC_URL}/images/products/payroll.webp`} className='mx-auto d-block w-70'/>
                    </div>
                   
            </div>

            <div className='row mt-5' id='staff'>

            <div className='col-md-6 text-right'>
                    <img src={`${process.env.PUBLIC_URL}/images/products/supportstaff.webp`}/>
                    </div>
            <div className='col-md-6 right-content p-4 '>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Support Staff Management</h2>
                        <p>Effectively manage support staff information, such as</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Staff Roles</li>
                        <li><i class="fa fa-check"></i> Work Schedules</li>
                        <li><i class="fa fa-check"></i> Attendance</li>
                        <li><i class="fa fa-check"></i> Task Assignments</li>
                       
                    </ul>
                    <p>Ensuring smooth operations across all departments.</p>
                    </div>
                  
                   
            </div>


            <div className='row mt-5' id='payroll'>
            <div className='col-md-6 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Payroll Management</h2>
                        <p>Simplify payroll processing for</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Faculty and Staff</li>
                        <li><i class="fa fa-check"></i> Automate Salary Calculations</li>
                        <li><i class="fa fa-check"></i> Deductions</li>
                        <li><i class="fa fa-check"></i> Tax Filings</li>
                       
                    </ul>
                    <p>and generate detailed payroll reports with ease</p>
                    </div>
                    <div className='col-md-6 text-left'>
                    <img src={`${process.env.PUBLIC_URL}/images/products/payroll.webp`} className='mx-auto d-block w-70'/>
                    </div>
                   
            </div>



            <div className='row mt-5' id='library'>
               
            <div className='col-md-6 text-right'>
               <img src={`${process.env.PUBLIC_URL}/images/products/Librarys.webp`}/>
               </div>

               <div className='col-md-6 right-content p-4'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Library Management</h2>
                   <p>Streamline library operations with features for</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> Cataloging</li>
                   <li><i class="fa fa-check"></i> Circulation</li>
                   <li><i class="fa fa-check"></i> Inventory Management</li>
                 
                   
                  
               </ul>
               <p>and generating library usage reports.</p>
              
               </div>


              
            </div>


            <div className='row mt-5' id='store'>
            
            <div className='col-md-6 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Store Management</h2>
                        <p>Efficiently manage institutional stores</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Inventory Tracking</li>
                        <li><i class="fa fa-check"></i> Sales Transactions</li>
                        <li><i class="fa fa-check"></i> Vendor Management</li>
                        <li><i class="fa fa-check"></i> Stock Management</li>
                       
                       
                    </ul>
                    <p>and generating sales reports for analysis.</p>
                    </div>


                    <div className='col-md-6 text-left'>
            <img src={`${process.env.PUBLIC_URL}/images/products/stock.webp`}/>
                    </div>
                  
                   
            </div>


            <div className='row mt-5' id='hostel'>
               
               <div className='col-md-6 mt-3 text-right'>
               <img src={`${process.env.PUBLIC_URL}/images/products/accounts.webp`} className='mx-auto d-block'/>
               </div>
               <div className='col-md-6 mt-3 right-content p-4'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Hostel Management</h2>
                   <p>Hostel management involves overseeing accommodation, facilities, and services for residents. It ensures smooth operations, enhances resident experience, and maintains safety, cleanliness, and discipline within the hostel.</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> Hostel Room Allocation</li>
                   <li><i class="fa fa-check"></i> Fee Management</li>
                   <li><i class="fa fa-check"></i> Student Grievances</li>
                   <li><i class="fa fa-check"></i> Facilities Management</li>
                  
               </ul>
               </div>
       </div>




       <div className='row mt-5' id='infrastructure'>
            
            <div className='col-md-6 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Infrastructure Management</h2>
                        <p>Maintain detailed records of campus infrastructure</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Movable & Immovable Properties</li>
                        <li><i class="fa fa-check"></i> Facilities</li>
                        <li><i class="fa fa-check"></i> Equipments</li>
                        <li><i class="fa fa-check"></i> Maintenance Schedules</li>
                       
                       
                    </ul>
                    <p>ensuring proper upkeep and utilization.</p>
                    </div>


                    <div className='col-md-6 text-left'>
            <img src={`${process.env.PUBLIC_URL}/images/products/infrastucture.webp`}/>
                    </div>
                 
                   
            </div>



            <div className='row mt-5' id='fee'>
               
               <div className='col-md-6 mt-3 text-right'>
               <img src={`${process.env.PUBLIC_URL}/images/products/accounts.webp`} className='mx-auto d-block'/>
               </div>
               <div className='col-md-6 mt-3 right-content p-4'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Fee Management</h2>
                   <p>Fee management simplifies financial operations by streamlining fee collection, enabling secure online payments, automating receipts, and providing real-time reports for better transparency and efficiency.</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> Fee Payment</li>
                   <li><i class="fa fa-check"></i> Multiple Payment Option</li>
                 
               </ul>
               </div>
       </div>



            
        <div className='row mt-5' id='accounts'>
               
                  
                    <div className='col-md-6 mt-3 right-content left-text'>
                    <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Accounts Management</h2>
                        <p>Seamlessly track and manage finances, including budgeting, invoicing, payments, and financial reporting, ensuring transparency and accuracy in financial management.</p>
                    <ul className='mt-3 list-manage'>
                        <li><i class="fa fa-check"></i> Fee Collection</li>
                        <li><i class="fa fa-check"></i> Salary Payments</li>
                        <li><i class="fa fa-check"></i> Purchasing</li>
                        <li><i class="fa fa-check"></i> Maintenance Expenses</li>
                        <li><i class="fa fa-check"></i> Generating Invoices/Receipts</li>
                        <li><i class="fa fa-check"></i> Generating Monthly/Annual Reports</li>
                    </ul>
                    </div>

                    <div className='col-md-6 mt-3 text-left' >
                    <img src={`${process.env.PUBLIC_URL}/images/products/accounts.webp`} className='mx-auto d-block'/>
                    </div>
            </div>


           

  

            <div className='row mt-5' id='vehicle'>
               
            <div className='col-md-6 text-left'>
               <img src={`${process.env.PUBLIC_URL}/images/products/vehicle.webp`}/>
               </div>
               <div className='col-md-6 right-content p-4'>
               <h2 className="" style={{ fontSize: '30px',color: '#000'}}>Vehicle Management</h2>
                   <p>Track and manage institutional vehicles</p>
               <ul className='mt-3 list-manage'>
                   <li><i class="fa fa-check"></i> Route Scheduling</li>
                   <li><i class="fa fa-check"></i> Maintenance</li>
                   <li><i class="fa fa-check"></i> Fuel Usage & Expences</li>
                   <li><i class="fa fa-check"></i> Driver Assignments</li>
                   
                  
               </ul>
               <p>Optimizing transportation efficiency and safety</p>
              
               </div>
              
            </div>

         

           


           

            
        </div>
        <div className='container'>
        <div className='row mt-5'>
                <div className='col-md-12 my-5 text-center whychoose-head'>
                <h2 className="" style={{ fontSize: '37px',color: '#000'}}>Why Choose Us</h2>
                </div>
                <div className='col-md-6 edu-why mb-5 '>
                     <h4 className='p-3 mb-3' style={{ fontSize: '17px', margin: 0 }}>Fully Customized Solutions</h4>
                     <p>Tailored to your specific requirements.
                     Whether you need personalized features, branding, or integrations, we'll work closely with you to ensure our software meets your exact needs.</p>
                </div>
                <div className='col-md-6 edu-why mb-5'>
                     <h4 className='p-3 mb-3' style={{ fontSize: '17px', margin: 0 }}>On-Site Support for a Week</h4>
                     <p>With our on-site support for a week, our expert team will be there to assist you during the initial setup, implementation, and training phases.
                     We'll ensure a smooth transition.</p>
                </div>
                <div className='col-md-6 edu-why mb-5'>
                     <h4 className='p-3 mb-3' style={{ fontSize: '17px', margin: 0 }}>On-Call Support</h4>
                     <p>We offer reliable on-call support to assist you whenever you need it.
                     Our dedicated support team is just a phone call away.</p>
                </div>
                <div className='col-md-6 edu-why mb-5'>
                     <h4 className='p-3 mb-3' style={{ fontSize: '17px', margin: 0 }}>Video Call Support if Required</h4>
                     <p>Sometimes, a face-to-face conversation is the most effective way to address complex issues or provide detailed guidance.</p>
                </div>
            </div>
        </div>

        <FloatingButton/>

        </>
    )
}

export default EduManage;