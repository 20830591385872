import React, { useState } from "react";
import RegisterPlan from "./RegisterPlan"; // Import RegisterPlan component

const plans = ["Starter Plan", "Basic Plan", "Advanced Plan", "Premium Plan"];

const features = [
  { name: "Admission Management", values: [true, true, true, true] },
  { name: "Co-Curriculum Management", values: ["Limited", true, true, true] },
  { name: "Student Management", values: [true, true, true, true] },
  { name: "Teacher Management", values: [true, true, true, true] },
  { name: "Exam Management", values: [true, true, true, true] },
  { name: "Time Table Management", values: [true, true, true, true] },
  { name: "Support Staff Management", values: [true, true, true, true] },
  { name: "Payroll Management", values: [true, true, true, true] },
  { name: "Library Management", values: [false, "Limited", true, true] },
  { name: "Store Management", values: [true, "Limited", true, true] },
  { name: "Hostel Management", values: [false, false, "Limited", true] },
  { name: "Infrastructure Management", values: [false, false, "Limited", true] },
  { name: "Fee Management", values: [true, true, true, true] },
  { name: "Cost Management", values: [true, true, true, true] },
  { name: "Vehicle Management", values: ["Limited", true, true, true] },
  { name: "Payment Gateway Integration", values: ["Add-on Service", "Add-on Service", true, true] },
  { name: "AI Features", values: ["Limited", "Limited", "Limited", true] },
  { name: "Virtual Class", values: ["Limited", false, "Limited", true] },
  { name: "SMS Notifications", values: ["Limited", false, "Limited", true] },
  { name: "Storage", values: ["2GB (extended)", "5GB", "20GB", "50GB"] },
  { name: "Mobile App", values: ["Limited", "Limited Access", "Limited Access", true] },
  { name: "Cloud Storage", values: [true, true, true, true] },
  { name: "Geo Fencing", values: [false, true, true, true] },
  { name: "Data Encryption", values: [true, true, true, true] },
  { name: "Support & Maintenance", values: ["Remote", true, true, "Priority"] },
  { name: "Biometric / Facial Recognition Integration", values: [false, false, true, true] },
  { name: "Multi-School Management", values: [false, false, "Add-on Service", "Add-on Service"] }
];

const PlansTabs = () => {
  // Initial button texts
  const initialTexts = {
    "Starter Plan": "Get One Year Free",
    "Basic Plan": "Get One Year Free",
    "Advanced Plan": "Get One Year Free",
    "Premium Plan": "Buy Now",
  };

  const [buttonTexts, setButtonTexts] = useState(initialTexts);

  const handleRegisterClick = (plan) => {
    setButtonTexts((prev) => ({ ...prev, [plan]: "Processing..." }));

    setTimeout(() => {
      setButtonTexts((prev) => ({ ...prev, [plan]: "Registered!" }));
    }, 2000);
  };

  return (
    <div className="container mt-5 pt-5">
      <div className="p-4 mt-5 pt-5">
        <div className="row">
          <div className="col-md-12 text-center my-3 feature-head">
            <h2 style={{ fontSize: "37px", color: "#000" }}>
              The right plan for you!
            </h2>
            <br />
          </div>
        </div>

        {/* Table wrapper for mobile scrolling */}
        <div className="table-container">
          <table className="pricing-table">
            <thead>
              <tr>
                <th className="feature-column">Feature</th>
                {plans.map((plan, index) => (
                  <th key={index}>{plan}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {features.map((feature, i) => (
                <tr key={i}>
                  <td className="feature-column">{feature.name}</td>
                  {feature.values.map((value, j) => (
                    <td key={j} className="text-center">
                      {value === true ? (
                        <i className="fa fa-check"></i>
                      ) : value === false ? (
                        <i className="fa fa-close text-red-500"></i>
                      ) : (
                        value
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              {/* Register Button Row */}
              <tr>
                <td className="feature-column font-bold">Select Plan</td>
                {plans.map((plan, index) => (
                  <td key={index} className="text-center">
                    <RegisterPlan
                      plan={plan}
                      buttonText={buttonTexts[plan]}
                      onClick={() => handleRegisterClick(plan)}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PlansTabs;
