import React from "react";
import { useState } from "react";
import RegisterPlan from './RegisterPlan';


const PricingPlans = () => {
     const [expanded, setExpanded] = useState(false);
        const [expanded2, setExpanded2] = useState(false);
        const [expanded3, setExpanded3] = useState(false);
        const [expanded4, setExpanded4] = useState(false);
    return (
        <div className="container-fluid">
 <div className="pricing-container mb-5">
            <h2 className="text-center mb-4" style={{color:'#000'}}>Our Pricing Plans</h2>
           <div class="row">
            <div className="col-md-3">
            <div className="shadow p-4 plans my-5">
                <h4 className="text-center position-relative">
                    Starter Plan
                    <br />
                    <span className="badge position-absolute custom-badge">
                        Free Now
                    </span>
                </h4>

                <h5 className="text-center py-4">
                    <i className="fa fa-rupee"></i>2,999/month <br />
                    <small>(upto 300 Students)</small>
                </h5>

                <div className="price-content">
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Admission Management </p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> CoCurriculum Management *</p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Student Management </p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Teacher Management </p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Exam Management </p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Library Management </p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Store Management </p>
                    </div>
                    

                    {/* Show hidden content only when expanded */}
                    {expanded && (
                        <>
                        <div className="feat py-4">
                        <p><i className="fa fa-close"></i> Hostel Management </p>
                    </div>
                    <div className="feat py-4">
                        <p><i className="fa fa-check"></i> Mobile App *</p>
                    </div>
                            <div className="feat py-4">
                                <p><i className="fa fa-check"></i> Vehicle Management*</p>
                            </div>
                            <div className="feat py-4">
                                <p><i className="fa fa-check"></i> AI Features *</p>
                            </div>
                            <div className="feat py-4">
                                <p><i className="fa fa-check"></i> Virtual Class*</p>
                            </div>
                            <div className="feat py-4">
                                <p><i className="fa fa-check"></i> SMS Notifications*</p>
                            </div>
                            <div className="feat py-4">
                                <p><i className="fa fa-check"></i> Storage (2GB-extended)</p>
                            </div>
                            <div className="feat py-4">
                                <p><i className="fa fa-close"></i> Multi-School Management</p>
                            </div>
                        </>
                    )}

                    {/* Expand/Collapse Button */}
                    <div className="text-center my-3">
                        <button className="btn btn-link p-2" onClick={() => setExpanded(!expanded)}>
                            {expanded ? (
                                <div class="">
                                 View Less
                                </div>
                               
                            ) : (
                                <>
                                View More
                                </>
                                
                            )}
                        </button>
                    </div>

                    {/* Always Visible */}
                    <div className="feat py-4 border-0 d-flex justify-content-center">
                        <a href="/pricing" className="p-2">All Features</a>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <RegisterPlan plan="Free Plan" buttonText="Get One Year Free" />
                </div>

                <div className="col-md-12 mt-5 mb-2 d-flex justify-content-center">
                    <p className="condition">*Limited, <span>**Add on Service</span></p>
                    
                </div>
                <p className="text-center gst">(GST as Applicable)</p>
            </div>
            </div>

            <div className="col-md-3">
            <div className='shadow p-4 plans my-5'>
           
           <h4 className="text-center position-relative">
   Basic Plan
   <br />
   <span className="badge position-absolute custom-badge">
       Free Now
   </span>
</h4>
               <h5 className='text-center py-4'><i className="fa fa-rupee"></i>5,999/month</h5>
               <div className='price-content'>
               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Admission Management </p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> CoCurriculum Management</p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Student Management </p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Teacher Management </p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-close"></i> Exam Management </p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-close"></i> Library Management </p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Store Management * </p>
               </div>
              
               {expanded2 && (
                       <>
                        <div className='feat py-4'>
               <p><i class="fa fa-close"></i> Hostel Management
</p>
               </div>

               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Mobile App *


</p>
               </div>
               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Vehicle Management</p>
               </div>

               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> AI Features *</p>
               </div>

               <div className='feat py-4'>
               <p><i class="fa fa-close"></i> Virtual Class</p>
               </div>

               <div className='feat py-4'>
               <p><i class="fa fa-close"></i> SMS Notifications</p>
               </div>

               <div className='feat py-4'>
               <p><i class="fa fa-check"></i> Storage (5GB)</p>
               </div>

               <div className='feat py-4'>
               <p><i class="fa fa-close"></i> Multi-School Management</p>
               </div>

               </>
                   )}

                   {/* Expand/Collapse Button */}
                   <div className="text-center my-3">
                       <button className="btn btn-link p-2" onClick={() => setExpanded2(!expanded2)}>
                           {expanded2 ?  (
                                <div class="">
                                 View Less
                                </div>
                               
                            ) : (
                                <>
                                View More
                                </>
                           )}
                       </button>
                   </div>
               <div className='feat py-4 border-0 d-flex justify-content-center'>
               <a href="/pricing" className='p-2'>All Features</a>
               </div>
               </div>
              
               
               <div className='d-flex justify-content-center'>
               <RegisterPlan plan="Basic Plan" buttonText="Get One Year Free"/>
               </div>
               <div className='col-md-12 mt-5 mb-2 d-flex justify-content-center'>
       <p className='condition'>*Limited, <span>**Add on Service</span> </p>
      </div>
      <p className="text-center gst">(GST as Applicable)</p>
           </div>
            </div>



            <div className="col-md-3">
            <div className='shadow p-4 plans my-5'>
                               
                               <h4 className="text-center position-relative">
                       Advanced Plan
                       <br />
                       <span className="badge position-absolute custom-badge">
                           Free Now
                       </span>
                   </h4>
                                   <h5 className='text-center py-4'><i className="fa fa-rupee"></i>9,999/month</h5>
                                   <div className='price-content'>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Admission Management </p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> CoCurriculum Management</p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Student Management </p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Teacher Management </p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Exam Management </p>
                                   </div>
                                   <div className='feat py-4'>
                                   
                                   <p><i class="fa fa-check"></i>Library Management </p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Store Management</p>
                                   </div>
                                  
                   {/* Show hidden content only when expanded */}
                   {expanded3 && (
                                           <>
                                            <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Hostel Management
                   *
                    </p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Mobile App *
                   
                   
                    </p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Vehicle Management *
                   
                   
                   
                    </p>
                                   </div>
                   
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> AI Features *</p>
                                   </div>
                   
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Virtual Class*</p>
                                   </div>
                   
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> SMS Notifications*</p>
                                   </div>
                   
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Storage (20GB)</p>
                                   </div>
                                   <div className='feat py-4'>
                                   <p><i class="fa fa-check"></i> Multi-School Management **</p>
                                   </div>
                                   </>
                                       )}
                   
                                       {/* Expand/Collapse Button */}
                                       <div className="text-center my-3">
                                           <button className="btn btn-link p-2" onClick={() => setExpanded3(!expanded3)}>
                                               {expanded3 ?  (
                                <div class="">
                                 View Less
                                </div>
                               
                            ) : (
                                <>
                                View More
                                </>
                                               )}
                                           </button>
                                       </div>
                                   <div className='feat py-4 border-0 d-flex justify-content-center'>
                                   <a href="/pricing" className='p-2'>All Features</a>
                                   </div>
                                   </div>
                                  
                                   <div className='d-flex justify-content-center'>
                                   <RegisterPlan plan="Advanced Plan" buttonText="Get One Year Free"/>
                                   </div>
                                   <div className='col-md-12 mt-5 mb-2 d-flex justify-content-center'>
                           <p className='condition'>*Limited, <span>**Add on Service</span> </p>
                          </div>
                          <p className="text-center gst">(GST as Applicable)</p>
                               </div>
            </div>

            <div className="col-md-3">
            <div className='shadow p-4 plans my-5 position-relative'>
        {/* Label */}
        
       

        <h4 className="text-center position-relative">
    Premium Plan
    
    
</h4>

        <h5 className='text-center py-4'>
            <i className="fa fa-rupee"></i>29,999/month
        </h5>
        <div className='price-content'>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Admission Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> CoCurriculum Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Student Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Teacher Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Exam Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Library Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Store Management</p>
            </div>
            
            {expanded4 && (
                        <>
                        <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Hostel Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Mobile App</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Vehicle Management</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> AI Features</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Virtual Class</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> SMS Notifications</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Storage (50GB)</p>
            </div>
            <div className='feat py-4'>
                <p><i className="fa fa-check"></i> Multi-School Management **</p>
            </div>
            </>
            )}
{/* Expand/Collapse Button */}
<div className="text-center my-3">
                        <button className="btn btn-link p-2" onClick={() => setExpanded4(!expanded4)}>
                            {expanded4 ?  (
                                <div class="">
                                 View Less
                                </div>
                               
                            ) : (
                                <>
                                View More
                                </>
                            )}
                        </button>
                    </div>
       
        <div className='d-flex justify-content-center mt-5'>
            <a href="/pricing" className='p-2'>All Features</a>
        </div>
        </div>
        <div className='d-flex justify-content-center'>
            <RegisterPlan plan="Premium Plan" buttonText="Buy Now"/>
        </div>
        <div className='col-md-12 mt-5 mb-2 d-flex justify-content-center'>
        <p className='condition'>*Limited, <span>**Add on Service</span> </p>
       </div>
       <p className="text-center gst">(GST as Applicable)</p>
    </div>
            </div>
           </div>
               
                

                
               
                

               
                   
               
               
              
                
        </div>
        </div>
       
    );
};

export default PricingPlans;
