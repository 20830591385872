import { Modal, Button, Form } from 'react-bootstrap';
import { useState } from "react";
import SchoolFAQ from './SchoolFAQ';
import FloatingButton from '../Services/FloatingButton';
import { Helmet } from 'react-helmet';
const School=()=>{
    const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      contact: "",
      message: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
      e.preventDefault();

      const { name, email, contact, message } = formData;

      if (!name || !email || !contact || !message) {
          setStatus("Please fill out all fields.");
          return;
      }

      setLoading(true);

      fetch("https://tricetechnologies.in/contact-form-handler.php", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
      })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false);
              if (data.status === "success") {
                  setFormData({ name: "", email: "", contact: "", message: "" });
                  setStatus("Message sent successfully!");
              } else {
                  setStatus(data.message || "Failed to send message.");
              }
          })
          .catch((error) => {
              setLoading(false);
              setStatus("Failed to send message. Please try again later.");
          });
  };
    return(
        <>
         <Helmet>
        <title>Advanced School Management Software in Palakkad, Kerala</title>
        <meta name='title' content='Advanced School Management Software in Palakkad, Kerala'/>
        <meta name="description" content="TriceEduCloud offers advanced school management software in Palakkad, Kerala, managing student attendance, admission, teacher and school information." />
        <link rel="canonical" href="https://tricetechnologies.in/school-management-software-palakkad-kerala" />
        
</Helmet>
        <div className="container pt-5 my-5">
            <div className="row pt-5 align-items-center mb-5 pb-5">
            
                    <div className="col-md-6 text-center pt-5">
                    <img src={`${process.env.PUBLIC_URL}/images/products/school-1.webp`} style={{width: '80%'}}/>
                    </div>

                    <div className="col-md-6 align-items-center sale-banner-left pt-5">
                        <div className="">
                        <img 
                            src={`${process.env.PUBLIC_URL}/images/edumanage.webp`} 
                            alt="web designing Company palakkad" 
                            style={{ maxWidth: '160px', marginRight: '15px',objectFit: 'contain' }} 
                        />
                        <h1 style={{ fontSize: '40px', margin: 0,lineHeight: '46px' }}>Advanced School Management Software in Palakkad,Kerala</h1>
                       <p className='mt-4'>Trice Edumanage by Trice Technologies is a smart, all-in-one school management software that streamlines operations, simplifies enrollment, tracks academics, and enhances productivity—accessible anytime on web and mobile.</p>
                        </div>
                       
                       
                        
                        <br></br>
                        <div className='d-flex'>
                        <a className="btn demo-btn mr-2" href='/best-school-and-college-management-software'>
                        View Plans
                    </a>
                    <a href="#school" className="btn btn-secondary " style={{background: 'antiquewhite'}}>View Features</a>
                    </div>
                        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Book a Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status && <p className="text-success">{status}</p>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 row align-items-center" controlId="formName">
                            <Form.Label className="col-sm-3 col-form-label">Name</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formEmail">
                            <Form.Label className="col-sm-3 col-form-label">Email</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formPhone">
                            <Form.Label className="col-sm-3 col-form-label">Phone No</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formDescription">
                            <Form.Label className="col-sm-3 col-form-label">Description</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <div className="text-end">
                            <Button variant="primary" type="submit" className="md-btn" disabled={loading}>
                                {loading ? "Sending..." : "Send"}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
                    </div>
            </div>

           

        </div>
        <div className='container-fluid edumanage-feature pt-5'>
        <div className='col-md-12 text-center school-strip'>
                <h2 className=''>Why Choose TriceEduManage School Management Software?</h2>
            </div>
            <div className='container'>
           
        <div className='row pb-5'>
            
            <div className='col-md-6 pt-5 text-right'>
            <img src={`${process.env.PUBLIC_URL}/images/products/school-2.webp`} className='w-80'/>
            </div>
            <div className='col-md-6 text-justify pt-5'>
                <p>A school management ERP ensures full integration and complete automation of financial, academic, administrative, and managerial processes. TriceEduManage stands out as the leading school management software in Palakkad, Kerala, designed to simplify administrative tasks and improve efficiency. With features like a student information management system, attendance tracking, and an education management system, it helps schools operate more smoothly. Trusted across Kerala, including cities like Calicut, Thrissur, Malappuram, Kannur, and Kochi, TriceEduManage supports effective management and increased productivity. Choose TriceEduManage to enhance your institution’s operations and stay competitive.</p>
                <div className="text-center d-flex flex-column flex-md-row align-items-center mt-5">
                <a className="btn demo-btn mr-2" href='/best-school-and-college-management-software'>
                        View Plans
                    </a>
  <a 
    href="https://wa.me/+919142121210" 
    target='_blank' 
    className="btn btn-success" 
    style={{background: '#25d366', color: '#fff', padding: '12px', borderRadius: '3px', display: 'flex', alignItems: 'center'}}
  >
    <i className="fa fa-whatsapp" style={{fontSize: '25px', marginRight: '8px'}}></i> 
    Click here for WhatsApp Chat
  </a>
</div>

            </div>
           

        </div>
            
            
            </div>
        </div>
        <div className='container-fluid school-feature' id='school'>
          
        <div className="container">
    <div className="row g-4">
            
           
        <div className="col-md-6 mt-5 p-5 ">
           
        <h3 className='text-left feature-head'>School Management System Modules</h3>
        <p className='text-white'>Explore the versatile modules of our school management software, designed to streamline academic and administrative processes. From attendance tracking and fee management to timetable scheduling and student performance analysis, each module is tailored for efficiency. Enhance communication among teachers, parents, and students while ensuring secure data management. Simplify operations and focus on delivering top-notch education</p>
            
        </div>


       



        <div className="col-md-3 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/accounts.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Account Management</h5>
          <p>Manage fee collection, student payments, generate invoices, and create detailed reports efficiently with ease.</p>
            
           
           </div>
            
        </div>


        <div className="col-md-3 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/teacher.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Teachers Management</h5>
           <p>Manage subject expertise, track class assignments, maintain leave records, and efficiently generate payroll for staff.</p>
            
           
           </div>
            
        </div>


        <div className="col-md-3 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/Library.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Library Management </h5>
         <p>Manages book details, circulation, reservations, and tracks overdue items efficiently for smooth library operations.</p>
            
           
           </div>
            
        </div>


        <div className="col-md-3 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/tennis.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Co curricular Activities Management</h5>
          <p>Tracks and organizes student involvement in extracurricular activities, clubs, and societies for better engagement and management.</p>
            
           
           </div>
            
        </div>



        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/pay.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Payroll Management</h5>
         <p>The system calculates employee salaries, bonuses, allowances, deductions, and generates payslips for accurate payroll management.</p>
            
           
           </div>
            
        </div>


        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/admission.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Admission Management</h5>
         <p>Simplifies the admission process by handling form submissions, eligibility checks, seat allocation, and fee processing efficiently.</p>
            
           
           </div>
            
        </div>



        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/staff.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Support Staff Management</h5>
         <p>Monitors staff information, including roles, schedules, and work assignments, ensuring efficient management and smooth operations.</p>
            
           
           </div>
            
        </div>




        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/store.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Store Management</h5>
         <p>Efficiently handle inventory, streamline order processing, manage suppliers, organize product catalogs, and generate insightful reports and analytics.</p>
            
           
           </div>
            
        </div>



        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/school-bus.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Vehicle Management </h5>
          <p>Efficient college transport  include vehicle schedules, route management, fuel tracking, and maintenance record keeping for smooth operations.</p>
            
           
           </div>
            
        </div>




        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/exam.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Exam Management</h5>
          <p>Manages exam scheduling, student registration, result processing, and grade distribution for streamlined academic operations.</p>
            
           
           </div>
            
        </div>




        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/hostel.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Hostel Management</h5>
          <p>Hostel room allocation, fee management, student grievances, and facilities management streamline campus operations and improve student satisfaction.</p>
            
           
           </div>
            
        </div>




        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/online.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Online Student Registration</h5>
         <p>The registration process for new students includes data entry and record creation to ensure accurate and organized student information.</p>
            
           
           </div>
            
        </div>



        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/certificate.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Merit List Generation</h5>
          <p>Generate merit lists, evaluate student performance, and determine eligibility for course admission with ease and accuracy.</p>
            
           
           </div>
            
        </div>



        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/tracker.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Live Tracking of Admission Status</h5>
          <p>Get real-time updates on your admission process, track application progress, and monitor the status of each admission.</p>
            
           
           </div>
            
        </div>


        <div className="col-md-3 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/online-pay.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Online Fees Payment</h5>
          <p>Real-time fee payment with multiple payment options for a seamless and convenient transaction experience</p>
            
           
           </div>
            
        </div>
       
    </div>
</div>
<br></br>
        </div>
       

<SchoolFAQ/>


<FloatingButton/>

        </>
    )
}

export default School;