import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const RegisterPlan = ({plan,buttonText}) => {
  const [btnName,setbtnName]=useState(buttonText);
  const [show, setShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(plan || "Default Plan");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    school: "",
    location: "",
    district: "",
    plan: selectedPlan
  });

  const handleShow = () => {
   
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false); // Reset loading state when modal is closed
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    try {
      const response = await fetch("https://tricetechnologies.in/register.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, plan: selectedPlan })
      });

      const data = await response.json();
      alert(data.message);
      handleClose();
    } catch (error) {
      alert("Something went wrong! Please try again.");
      setLoading(false); // Stop spinner on error
    }
  };

  return (
    <div>
      {/* Register Now Button */}
      <div className="d-flex mt-2">
      <a
  style={{
    backgroundColor: "#0e85a8",
    color: "#fff",
    borderRadius: "20px",
  }}
  href="#"
  className="btn rounded p-3"
  onClick={() => handleShow()}
>
  {btnName}
</a>

      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Name</label>
              <input type="text" name="name" className="form-control inputs" placeholder="Enter your name" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">Mobile</label>
              <input type="tel" name="mobile" className="form-control inputs" placeholder="Enter your mobile number" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">Email</label>
              <input type="email" name="email" className="form-control inputs" placeholder="Enter your email" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">School Name</label>
              <input type="text" name="school" className="form-control inputs" placeholder="Enter school name" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">Location</label>
              <input type="text" name="location" className="form-control inputs" placeholder="Enter location" required onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label className="form-label">District</label>
              <input type="text" name="district" className="form-control inputs" placeholder="Enter district" required onChange={handleChange} />
            </div>
           
            
            <button type="submit" className="btn btn-primary p-2 mt-3" disabled={loading}>
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> Registering...
                </>
              ) : (
                "Register"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterPlan;
